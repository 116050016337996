import React from "react";
import {
  VStack,
  HStack,
  Text,
  Input,
  IconButton,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";

interface ScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDate: string;
  selectedTimes: string[];
  handleSelecteData: (date: string) => void;
  handleAddTimeInput: () => void;
  handleRemoveTimeInput: (index: number) => void;
  handleAddAppointment: () => void;
  handleUpdateTime: (updatedTimes: string[]) => void; // Nova função para atualizar os horários
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({
  isOpen,
  onClose,
  selectedDate,
  selectedTimes,
  handleSelecteData,
  handleAddTimeInput,
  handleRemoveTimeInput,
  handleAddAppointment,
  handleUpdateTime,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent>
        <ModalHeader>Horários</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w={"100%"} align={"start"}>
            <HStack w={"100%"}>
              <Text>Dia:</Text>
              <Input
                type="date"
                placeholder="Selecione a Data"
                value={selectedDate}
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) => handleSelecteData(e.target.value)}
              />
            </HStack>
            {selectedTimes.map((time, index) => (
              <HStack key={index} mt={3} w={"100%"}>
                <Text>Horário:</Text>
                <Input
                  type="time"
                  placeholder="Adicione Horário"
                  value={time}
                  w={"100%"}
                  onChange={(e) => {
                    const updatedTimes = [...selectedTimes];
                    updatedTimes[index] = e.target.value;
                    handleUpdateTime(updatedTimes); // Atualiza o array de horários no componente pai
                  }}
                />
                <IconButton
                  icon={<FaTrash />}
                  aria-label="Remover"
                  colorScheme="red"
                  onClick={() => handleRemoveTimeInput(index)}
                />
              </HStack>
            ))}
            <Button mt={3} onClick={handleAddTimeInput}>
              Adicionar
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleAddAppointment}>
            Atualizar
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScheduleModal;
