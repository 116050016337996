import { Route, Routes } from "react-router-dom";
import SignIn from "../pages";
import { MentoradoHome } from "../pages/mentorado/home";
import { UsersAdmin } from "../pages/admin/users";
import { RegisterNewUser } from "../pages/admin/users/register";
import { ViewUser } from "../pages/admin/users/view";
import { UsersMentor } from "../pages/admin/users/mentor";
import { UsersMentorado } from "../pages/admin/users/mentorado";
import { MentoradoProfile } from "../pages/mentorado/profile";
import { MentoradoConfig } from "../pages/mentorado/config";
import { MentoradoChangePassword } from "../pages/mentorado/config/changePassword";
import MentoradoTrilha from "../pages/mentorado/trilha";
import { MentoradoEmailNotifications } from "../pages/mentorado/config/emailNotifications";
import { GoogleOAuth2 } from "../pages/oauth2/GoogleOAuth2";
import { GoogleOAuth2Redirect } from "../pages/oauth2/GoogleOAuth2/GoogleOAuth2Redirect";
import MentoradoProgramacao from "../pages/mentorado/programacao";
import { JornadaTrilha } from "../pages/admin/trilha";
import { MentoradoMentor } from "../pages/mentorado/mentor";
import { RegisterMentoria } from "../pages/master/mentorias/register";
import { Agenda } from "../pages/admin/agenda";
import { MasterHome } from "../pages/master/home";
import { ViewMentoria } from "../pages/master/mentorias/view";
import { GestaoAdm } from "../pages/admin/gestao";
import { UpdateMentoria } from "../pages/master/mentorias/update";
import { Evento } from "../pages/admin/evento";
import { RegisterNewEvento } from "../pages/admin/evento/register";
import { AgendaMentor } from "../pages/mentor/agenda";
import { EventoMentor } from "../pages/mentor/evento";
import { UsersMentoradoMentor } from "../pages/mentor/users/mentorado";
import { MentorHome } from "../pages/mentor/home";
import { ViewTemplate } from "../pages/admin/users/viewTemplate";
import { ViewUserMentor } from "../pages/mentor/users/view";
import { ResetPassword } from "../pages/resetPassword";
import { MentorProfile } from "../pages/admin/users/mentor/profile";
import { Dashboard } from "../pages/admin/dashboard";
import SelecionaMentoria from "../pages/selecionaMentoria";
import { ConfiguracaoMentoria } from "../pages/admin/users/configuracaoMentoria";
import { MentorDetailsPage } from "../pages/admin/MentorDetails";
// import { Agendamentos } from "../pages/admin/agendamentos"

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />

      <Route path="/seleciona-mentoria" element={<SelecionaMentoria />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/home" element={<MentoradoHome />} />

      <Route path="/oauth/google" element={<GoogleOAuth2 />} />
      <Route path="/oauth/google/redirect" element={<GoogleOAuth2Redirect />} />

      <Route path="/mentorado/home" element={<MentoradoHome />} />
      <Route path="/mentorado/mentor" element={<MentoradoMentor />} />
      <Route path="/mentorado/programacao" element={<MentoradoProgramacao />} />
      <Route path="/mentorado/trilha" element={<MentoradoTrilha />} />
      <Route path="/mentorado/perfil" element={<MentoradoProfile />} />
      <Route path="/mentorado/config" element={<MentoradoConfig />} />
      <Route
        path="/mentorado/config/change-password"
        element={<MentoradoChangePassword />}
      />
      <Route
        path="/mentorado/config/email-notifications"
        element={<MentoradoEmailNotifications />}
      />

      <Route path="/mentor/agenda" element={<AgendaMentor />} />
      <Route path="/mentor/home" element={<MentorHome />} />

      <Route path="/mentor/eventos" element={<EventoMentor />} />
      <Route
        path="/mentor/users-mentorados"
        element={<UsersMentoradoMentor />}
      />
      <Route
        path="/mentor/users-mentorados/view"
        element={<ViewUserMentor />}
      />

      <Route path="/admin/users" element={<UsersAdmin />} />
      <Route path="/admin/home" element={<Dashboard />} />

      <Route path="/admin/agenda" element={<Agenda />} />
      <Route path="/admin/agenda/mentor/:id" element={<MentorDetailsPage />} />
      <Route path="/admin/gestao" element={<GestaoAdm />} />
      <Route path="/admin/jornada-trilha" element={<JornadaTrilha />} />
      {/* <Route path="/admin/users-mentores" element={<UsersMentor />} /> */}
      <Route path="/admin/users-mentores" element={<UsersMentor />} />
      <Route path="/admin/users-mentorados" element={<UsersMentorado />} />
      <Route path="/admin/config-mentoria" element={<ConfiguracaoMentoria />} />

      <Route
        path="/admin/users/register-new-user"
        element={<RegisterNewUser />}
      />
      <Route path="/admin/users/view" element={<ViewTemplate />} />
      <Route path="/admin/users/mentor" element={<MentorProfile />} />
      <Route path="/admin/eventos" element={<Evento />} />
      <Route path="/admin/eventos/cadastrar" element={<RegisterNewEvento />} />
      {/* <Route path="/admin/agendamentos" element={<Agendamentos />} /> */}

      <Route path="/master/home" element={<MasterHome />} />
      <Route path="/master/mentoria/view" element={<ViewMentoria />} />
      <Route path="/master/mentoria/update" element={<UpdateMentoria />} />
      <Route path="/master/register" element={<RegisterMentoria />} />
    </Routes>
  );
}
