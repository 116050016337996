import React from "react";
import {
  VStack,
  HStack,
  Avatar,
  Text,
  IconButton,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { IMentorAgendamento } from "../../../../helpers/agendaHelper";
import CalendarComponent from "./CalendarComponent";

interface MentorDetailsProps {
  mentorData: IMentorAgendamento;
  isLoading: boolean;
  handleRemoveAppointment: (date: string) => void;
  handleCalendarDateClick: (date: Date) => void;
  onOpen: () => void;
}

export const MentorDetails: React.FC<MentorDetailsProps> = ({
  mentorData,
  isLoading,
  handleRemoveAppointment,
  handleCalendarDateClick,
  onOpen,
}) => {
  const sortedDates = Object.keys(mentorData.agendamentos).sort((a, b) => {
    // Converte a data no formato "DD/MM/YYYY" para "YYYY-MM-DD" antes de comparar
    const [dayA, monthA, yearA] = a.split("/");
    const [dayB, monthB, yearB] = b.split("/");

    const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
    const dateB = new Date(`${yearB}-${monthB}-${dayB}`);

    return dateA.getTime() - dateB.getTime();
  });

  return (
    <VStack w={"100%"} align={"start"}>
      {/* Header com informações do mentor */}
      <HStack mt={5}>
        <Avatar
          h={"60px"}
          w={"60px"}
          src={`${process.env.REACT_APP_API}/tmp/user/${mentorData.img}`}
        />
        <Text>{mentorData.name}</Text>
      </HStack>

      {/* Título e botão de adicionar horário */}
      <HStack
        mt="5"
        alignSelf={"center"}
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontSize={"24px"} fontWeight={500}>
          Horários disponíveis
        </Text>
        <IconButton
          icon={<FaPlus size={25} color="" />}
          aria-label="Adicionar"
          bgColor="xmentor.primary"
          color="white"
          onClick={onOpen}
        />
      </HStack>

      {/* Spinner de carregamento enquanto os dados estão sendo buscados */}
      {isLoading ? (
        <Center w={"100%"}>
          <Spinner />
        </Center>
      ) : (
        <Stack direction={["column", "row"]} spacing="24px" w={"100%"}>
          {/* Tabela de horários */}
          <Table mt="5" variant="simple" size={"sm"} h={"100px"}>
            <Thead borderRadius={"8px"}>
              <Tr>
                <Th>Data</Th>
                <Th>Horários</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedDates.map((date) => (
                <Tr key={date}>
                  <Th>{date}</Th>
                  <Th>
                    {mentorData.agendamentos[date].map((t) => `${t.time} `)}
                  </Th>
                  <Th>
                    <IconButton
                      size={"sm"}
                      icon={<FaTrash color="red" />}
                      aria-label="Remover"
                      // colorScheme="red"
                      bg={"none"}
                      onClick={() => handleRemoveAppointment(date)}
                    />
                  </Th>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Componente de calendário */}
          <CalendarComponent
            agendamentos={mentorData.agendamentos}
            handleCalendarDateClick={handleCalendarDateClick}
          />
        </Stack>
      )}
    </VStack>
  );
};
