import React, { useCallback, useEffect, useState } from "react";
import {
  Divider,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CardMentorias } from "../../../components/CardMentorias";
import { CardAtividadesMentorado } from "../../../components/CardAtividadesMentorado";
import { CalendarioMentorado } from "../../../components/CalendarioMentorado";
import { TemplateMentorado } from "../../../components/layout/mentorado/TemplateMentorado";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { CardMentor } from "../../../components/CardMentor";
import { useNavigate } from "react-router-dom";
import { CardMentorAgendaAberta } from "../../../components/CardMentorAgendaAberta";
import {
  IAgendamentoClosed,
  IAgendamentoFree,
  IAgendamentoFreeAPI,
  getUserAgendaAPI,
} from "../../../helpers/agendaHelper";

interface IEventsProps {
  date: string;
  info: string;
}
export function MentoradoHome() {
  const [closedAgenda, setClosedAgenda] = useState<IAgendamentoClosed[]>([]);
  const [openAgenda, setOpenAgenda] = useState<IAgendamentoFreeAPI[]>([]);
  const [formatedDate, setFormatedDate] = useState<IEventsProps[]>([]);
  const { FindActivity, activity, payLoad, SearchUser, users } = useAuth();

  const navigate = useNavigate();

  const Auth = useAuth();
  const RefreshAgenda = async () => {
    const response = await getUserAgendaAPI(
      Auth.payLoad?.user.id as string,
      false
    );
    if (response.msg === "ok") {
      setOpenAgenda(response.events.free);
      setClosedAgenda(response.events.marked);
      const dates: IEventsProps[] = [];
      response.events.marked.forEach((e) => {
        const fDate = e.data.split("/");
        dates.push({
          date: `${fDate[2]}-${fDate[1]}-${fDate[0]}`,
          info: `Reunião marcada com ${e.mentor}`,
        });
      });
      setFormatedDate((prev) => [...prev, ...dates]);
    }
  };

  useEffect(() => {
    if (payLoad && payLoad.user.id) FindActivity(payLoad?.user.id, "0");

    if (payLoad?.user.mentoria_id)
      SearchUser({
        nome: "",
        pagina: 0,
        perfil: "mentor",
        mentoria_id: payLoad?.user.mentoria_id,
        mentor: "",
      });

    RefreshAgenda();
  }, []);

  useEffect(() => {
    if (activity.length > 0) {
      const dates: IEventsProps[] = [];
      activity.forEach((e) => {
        return dates.push({
          date: e.data_fato,
          info: `Atividade - ${e.titulo}`,
        });
      });
      setFormatedDate((prev) => [...prev, ...dates]);
    }
  }, [activity]);

  const updateData = () => {
    if (payLoad && payLoad.user.id) {
      const idUser = payLoad.user.id;
      new Promise(async (resolve) => {
        FindActivity(idUser, "0");
      });
      setTimeout(async () => {
        FindActivity(idUser, "0");
      }, 750);
    }
  };

  const isFutureDate = (dateStr: string, timeStr: string) => {
    const [day, month, year] = dateStr.split("/");
    const [hour, minute] = timeStr.split(":");
    const eventDate = new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);

    // Adicionando 1 hora de tolerância ao horário atual
    const now = new Date();
    now.setHours(now.getHours() - 1);

    return eventDate >= now;
  };

  return (
    <TemplateMentorado updateNotification={updateData}>
      <Grid
        templateColumns={{ base: "100%", lg: "60% calc(60% - 336px)" }}
        gap={"24px"}
        w="100%"
        h="100%"
      >
        <GridItem colSpan={1} rowSpan={1}>
          <VStack align={"start"} my={"15px"} spacing={0} fontWeight={"bold"}>
            <Text fontSize={30}>{payLoad?.user.nome},</Text>
            <Text fontSize={22}>Bem vindo(a)!</Text>
          </VStack>
          <VStack align={"start"} my={"36px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={24} mb={"24px"}>
              Próximas reuniões
            </Text>
            <HStack
              overflow={"auto"}
              maxW={"100%"}
              css={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {closedAgenda.filter((event) =>
                isFutureDate(event.data, event.time)
              ).length > 0 ? (
                closedAgenda
                  .filter((event) => isFutureDate(event.data, event.time))
                  .map((event) => {
                    return (
                      <CardMentorias
                        dia={event.data}
                        horario={event.time}
                        mentor={event.mentor || ""}
                        href={event.meet_link}
                      />
                    );
                  })
              ) : (
                <Text fontSize={"lg"} color={"gray"}>
                  Não há reuniões marcadas
                </Text>
              )}
            </HStack>
          </VStack>
          <VStack align={"start"} my={"40px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={24} mb={"16px"}>
              Atividades atuais
            </Text>
            <VStack
              bgColor="xmentor.background_content"
              w={["100%"]}
              borderRadius={10}
              spacing={0}
            >
              {activity.map((data, index) => (
                <React.Fragment key={index}>
                  <CardAtividadesMentorado
                    mentor={data.mentor.nome}
                    foto={data.mentor.foto || ""}
                    horario={data.data_cadastro}
                    atividade={data.titulo}
                    vencimento={data.data_fato}
                    feedback={data.file}
                    id={data.id}
                  />
                  {index !== activity.length - 1 && (
                    <Divider borderColor="gray.200" />
                  )}
                </React.Fragment>
              ))}
            </VStack>
          </VStack>

          <VStack align={"start"} my={"40px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={24} mb={"16px"}>
              Agenda aberta
            </Text>
            <GridItem colSpan={1} rowSpan={1} w={"100%"}>
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 2 }}
                w={"100%"}
                spacing={"24px"}
              >
                {openAgenda.length > 0 ? (
                  openAgenda.map((agenda) => {
                    if (!closedAgenda.find((i) => i.mentor === agenda.nome)) {
                      //GAMBIARRA (FILTRAR PELA API POR FUTURA VARIAVEL GLOBAL QUE DEFINE A QUANTIDADE DE REUNIOES SIMULTANEAS PERMITIDAS)
                      if (
                        agenda.agendamentos &&
                        Object.keys(agenda.agendamentos).length > 0
                      ) {
                        return (
                          <CardMentorAgendaAberta
                            mentor={agenda.nome}
                            img={`${process.env.REACT_APP_API}/tmp/user/${agenda.foto}`}
                            desc={"Especialidade"}
                            availableDates={agenda.agendamentos}
                            refreshCB={() => {
                              if (payLoad && payLoad.user.id)
                                FindActivity(payLoad?.user.id, "0");

                              if (payLoad?.user.mentoria_id)
                                SearchUser({
                                  nome: "",
                                  pagina: 0,
                                  perfil: "mentor",
                                  mentoria_id: payLoad?.user.mentoria_id,
                                  mentor: "",
                                });

                              RefreshAgenda();
                            }}
                          />
                        );
                      }
                    } else {
                      return (
                        <Text fontSize={"lg"} color={"gray"}>
                          Não há horários disponíveis
                        </Text>
                      );
                    }
                  })
                ) : (
                  <Text fontSize={"lg"} color={"gray"}>
                    Não há horários disponíveis
                  </Text>
                )}
              </SimpleGrid>
            </GridItem>
          </VStack>
        </GridItem>
        <GridItem rowSpan={2} colSpan={1} w={"100%"}>
          <VStack align={"start"} my={"40px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={24} mb={"16px"}>
              Calendário
            </Text>
            <CalendarioMentorado markedDates={formatedDate} />
          </VStack>
          <VStack align={"start"} my={"40px"} spacing={3}>
            <Text fontSize={24} mb={"16px"} w={"280px"}>
              Conheça nossos mentores
            </Text>
            {users &&
              users.data.map((data) => (
                <CardMentor
                  key={data.nome}
                  image={data.foto || ""}
                  mentor={String(data.nome)}
                  onClick={() => navigate("/mentorado/mentor", { state: data })}
                />
              ))}
          </VStack>
        </GridItem>
      </Grid>
    </TemplateMentorado>
  );
}
